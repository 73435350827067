export const state = () => ({
  showDoctorSignupModal: undefined,
  showPatientSignupModal: undefined,
  showPortalRegionModal: undefined
});

export const mutations = {
  setShowDoctorSignupModal(state, show) {
    state.showDoctorSignupModal = show;
  },
  setShowPatientSignupModal(state, show) {
    state.showPatientSignupModal = show;
  },
  setShowPortalRegionModal(state, show) {
    state.showPortalRegionModal = show;
  }
};

