export const state = () => ({
  searchText: ''
});

export const mutations = {
  setSearchText(state, searchText) {
    state.searchText = searchText;
  }
};

