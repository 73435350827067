<template>
  <form id="patientSignupForm"
        class="get-started-form"
        @submit.prevent="patientSignupForm_submitHandler">
    <div class="row">
      <fieldset name="Personal details">
        <div class="col-6 form-field">
          <label for="patientFirstName">First Name *</label>
          <input id="patientFirstName"
                required
                type="text"
                @input="validate()"/>
        </div>
        <div class="col-6 form-field">
          <label for="patientLastName">Last Name *</label>
          <input id="patientLastName"
                required
                type="text"
                @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="patientEmail">Email Address *</label>
          <input id="patientEmail"
                required
                @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="patientCountry">Country of Residence *</label>
          <input id="patientCountry"
                required
                type="text"
                @input="validate()" />
        </div>
      </fieldset>
      <fieldset name="Condition and hospital details">
        <div class="col-12 form-field">
          <p>What's your condition? *</p>
          <div class="form-check form-check-inline">
            <input id="patientSevereAsthma"
                  class="form-check-input"
                  type="radio"
                  name="conditionOptions"
                  value="Severe Asthma"
                  @change="validate()" />
            <label class="form-check-label" for="patientSevereAsthma">Severe Asthma</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="patientCysticFibrosis"
                  class="form-check-input"
                  type="radio"
                  name="conditionOptions"
                  value="Cystic Fibrosis"
                  @change="validate()" />
            <label class="form-check-label" for="patientCysticFibrosis">Cystic Fibrosis</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="patientIPF"
                  class="form-check-input"
                  type="radio"
                  name="conditionOptions"
                  value="IPF"
                  @change="validate()"/>
            <label class="form-check-label" for="patientIPF">IPF</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="patientCOPD"
                  class="form-check-input"
                  type="radio"
                  name="conditionOptions"
                  value="COPD"
                  @change="validate()" />
            <label class="form-check-label" for="patientCOPD">COPD</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="patientOther"
                  class="form-check-input"
                  type="radio"
                  name="conditionOptions"
                  value="Other"
                  @change="validate()" />
            <label class="form-check-label" for="patientOther">Other</label>
          </div>
        </div>
        <div class="col-12 form-field">
          <label for="patientHospitalName">Name of Hospital / Clinic *</label>
          <input id="patientHospitalName"
                required
                type="text"
                @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="patientPhysiciansName">Physician's Name *</label>
          <input id="patientPhysiciansName"
                required
                type="text"
                @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="patientPhysiciansEmail">Physician's Email *</label>
          <input id="patientPhysiciansEmail"
                type="email"
                @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <p>Have you already talked to your clinic about NuvoAir? *</p>
          <div class="form-check form-check-inline">
            <input id="patientTalkedYes"
                  required
                  class="form-check-input"
                  type="radio"
                  name="talkedAboutNuvoAir"
                  value="Yes"
                  @change="validate()" />
            <label class="form-check-label" for="patientTalkedYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="patientTalkedNo"
                  required
                  class="form-check-input"
                  type="radio"
                  name="talkedAboutNuvoAir"
                  value="No"
                  @change="validate()" />
            <label class="form-check-label" for="patientTalkedNo">No</label>
          </div>
        </div>
      </fieldset>
      <fieldset name="Consent">
        <div class="col-12 form-field">
          <p>I hereby consent to share my data with NuvoAir AB and be
            contacted to learn more about NuvoAir's services and
            solutions.</p>
          <div class="form-check form-check-inline">
            <input id="patientGDPRYes"
                  required
                  class="form-check-input"
                  type="checkbox"
                  @change="validate()" />
            <label class="form-check-label" for="patientGDPRYes">Yes</label>
          </div>
        </div>
      </fieldset>
    </div>
    <button type="submit"
            :disabled="submitting || !valid"
            class="btn btn-primary btn-pill main-button form-submit-button">
      Get Started
    </button>
  </form>
</template>
<script>
export default {
  data() {
    return {
      submitting: false,
      valid: false,
    };
  },
  methods: {
    validate() {
      this.valid =
        document.getElementById('patientFirstName').value &&
        document.getElementById('patientLastName').value &&
        document.getElementById('patientEmail').value &&
        document.getElementById('patientGDPRYes').checked;
    },
    patientSignupForm_submitHandler() {
      this.submitting = true;

      const patientConditionRB = document.querySelector('input[name="conditionOptions"]:checked');
      const talkedAboutNuvoAirRB = document.querySelector('input[name="talkedAboutNuvoAir"]:checked');

      // See: backend/api/signups/controllers/signups.js for impl
      const body = {
        first_name: document.getElementById('patientFirstName').value,
        last_name: document.getElementById('patientLastName').value,
        email: document.getElementById('patientEmail').value,
        country: document.getElementById('patientCountry').value,
        condition: patientConditionRB.value,
        hospital_name: document.getElementById('patientHospitalName').value,
        physician_name: document.getElementById('patientPhysiciansName').value,
        physician_email: document.getElementById('patientPhysiciansEmail').value,
        shared_nuvoair_with_clinic: talkedAboutNuvoAirRB.value === 'Yes',
        gdpr_consent: document.getElementById('patientGDPRYes').checked,
      };

      fetch(this.$config.strapiBaseUrl + '/signup/patient', {
        method: 'post',
        body: JSON.stringify(body),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        this.submitting = false;
        this.$store.commit('modals/setShowPatientSignupModal', false);
      });
    },
  },
};
</script>
