import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1e3c25ec = () => interopDefault(import('../pages/aos-inhalers.vue' /* webpackChunkName: "pages/aos-inhalers" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _d0a11270 = () => interopDefault(import('../pages/care-in-the-uk.vue' /* webpackChunkName: "pages/care-in-the-uk" */))
const _e9af5104 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _609e5d9d = () => interopDefault(import('../pages/clinical-trials.vue' /* webpackChunkName: "pages/clinical-trials" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _1b6aaa7e = () => interopDefault(import('../pages/evidence.vue' /* webpackChunkName: "pages/evidence" */))
const _c67b4cb2 = () => interopDefault(import('../pages/for-health-plans.vue' /* webpackChunkName: "pages/for-health-plans" */))
const _3b0a3c7c = () => interopDefault(import('../pages/for-patients.vue' /* webpackChunkName: "pages/for-patients" */))
const _530bda24 = () => interopDefault(import('../pages/for-providers.vue' /* webpackChunkName: "pages/for-providers" */))
const _49d7f682 = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _734e5acb = () => interopDefault(import('../pages/outcomes.vue' /* webpackChunkName: "pages/outcomes" */))
const _1c176355 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _7452cbd9 = () => interopDefault(import('../pages/whitepapers/index.vue' /* webpackChunkName: "pages/whitepapers/index" */))
const _99443ce2 = () => interopDefault(import('../pages/company/news.vue' /* webpackChunkName: "pages/company/news" */))
const _3f3b68f6 = () => interopDefault(import('../pages/join/marpai.vue' /* webpackChunkName: "pages/join/marpai" */))
const _489f3122 = () => interopDefault(import('../pages/share/roi.vue' /* webpackChunkName: "pages/share/roi" */))
const _9d03b872 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _23422166 = () => interopDefault(import('../pages/careers/_id.vue' /* webpackChunkName: "pages/careers/_id" */))
const _41b129c0 = () => interopDefault(import('../pages/docs/_slug.vue' /* webpackChunkName: "pages/docs/_slug" */))
const _729c2a91 = () => interopDefault(import('../pages/whitepapers/_slug.vue' /* webpackChunkName: "pages/whitepapers/_slug" */))
const _d343a01a = () => interopDefault(import('../pages/nvh/_region/tos.vue' /* webpackChunkName: "pages/nvh/_region/tos" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active exact',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about"
  }, {
    path: "/aos-inhalers",
    component: _1e3c25ec,
    name: "aos-inhalers"
  }, {
    path: "/blog",
    component: _999675e2,
    name: "blog"
  }, {
    path: "/care-in-the-uk",
    component: _d0a11270,
    name: "care-in-the-uk"
  }, {
    path: "/careers",
    component: _e9af5104,
    name: "careers"
  }, {
    path: "/clinical-trials",
    component: _609e5d9d,
    name: "clinical-trials"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/evidence",
    component: _1b6aaa7e,
    name: "evidence"
  }, {
    path: "/for-health-plans",
    component: _c67b4cb2,
    name: "for-health-plans"
  }, {
    path: "/for-patients",
    component: _3b0a3c7c,
    name: "for-patients"
  }, {
    path: "/for-providers",
    component: _530bda24,
    name: "for-providers"
  }, {
    path: "/legal",
    component: _49d7f682,
    name: "legal"
  }, {
    path: "/outcomes",
    component: _734e5acb,
    name: "outcomes"
  }, {
    path: "/support",
    component: _1c176355,
    name: "support"
  }, {
    path: "/whitepapers",
    component: _7452cbd9,
    name: "whitepapers"
  }, {
    path: "/company/news",
    component: _99443ce2,
    name: "company-news"
  }, {
    path: "/join/marpai",
    component: _3f3b68f6,
    name: "join-marpai"
  }, {
    path: "/share/roi",
    component: _489f3122,
    name: "share-roi"
  }, {
    path: "/blog/:slug",
    component: _9d03b872,
    name: "blog-slug"
  }, {
    path: "/careers/:id",
    component: _23422166,
    name: "careers-id"
  }, {
    path: "/docs/:slug?",
    component: _41b129c0,
    name: "docs-slug"
  }, {
    path: "/whitepapers/:slug",
    component: _729c2a91,
    name: "whitepapers-slug"
  }, {
    path: "/nvh/:region?/tos",
    component: _d343a01a,
    name: "nvh-region-tos"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
