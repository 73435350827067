<template>
  <div class="modal-dialog modal-dialog-centered portal-region-selector" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button"
                class="close modal-close-button btn btn-sm btn-rounded-circle btn-white"
                aria-label="Close"
                @click="closeButton_clickHandler">
          <span class="fe fe-x" />
        </button>
        Select Region
      </div>
      <div class="modal-body d-flex flex-column">
        <div class='d-flex flex-row align-items-center mb-6'>
          <img src='/img/EU-emoji.svg' class='mr-1' />
          <a :href="caregiverPortalUrlEU"
             target='_blank'
             @click='portalLinkClickHandler'
             class='navbar-btn btn btn-sm btn-primary btn-pill lift ms-auto mobile-nav-blue-btn'>EU Provider Portal</a>
        </div>
        <div class='d-flex flex-row align-items-center'>
          <img src='/img/US-emoji.svg' class='mr-1' />
          <a :href="caregiverPortalUrlUSA"
             target='_blank'
             @click='portalLinkClickHandler'
             class='navbar-btn btn btn-sm btn-primary btn-pill lift ms-auto mobile-nav-blue-btn'>US Provider Portal</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './portalRegionModalDialog.scss';
import GlobalMixin from '~/mixins/GlobalMixin';

export default {
  mixins: [GlobalMixin],
  computed: {
    caregiverPortalUrlEU() {
      return this.globalSetting("portal_url", "");
    },
    caregiverPortalUrlUSA() {
      return this.globalSetting("portal_url_usa", "");
    },
  },
  methods: {
    closeButton_clickHandler() {
      this.$store.commit('modals/setShowPortalRegionModal', false);
    },
    portalLinkClickHandler() {
      this.$store.commit('modals/setShowPortalRegionModal', false);
    }
  },
};
</script>
