<template>
  <core-data-loader>
    <Header/>
    <div class="page-container" :style="{'margin-top': `${bannerHeight + headerHeight}px`}">
      <nuxt />
    </div>
    <Footer />
    <modals />
  </core-data-loader>
</template>

<script>
import Header from "~/layouts/Header";
import Footer from "~/layouts/Footer";
import Modals from '~/components/modals';
import CoreDataLoader from "~/components/coreDataLoader";
import './default.scss';
import "owl-carousel/owl-carousel/owl.carousel.css";

export default {
  components: {
    Header,
    Footer,
    Modals,
    CoreDataLoader
  },
   data() {
    return {
      bannerHeight: 0,
      headerHeight: 0,
    };
  },
  mounted() {
    this.bannerHeight = document.getElementById('headerBanner') ? document.getElementById('headerBanner').clientHeight : 0;
    this.headerHeight = document.getElementById('headerNavbar') ? document.getElementById('headerNavbar').clientHeight : 0;
    window.addEventListener("resize", this.window_resizeHandler);
    this.window_resizeHandler();
  },
  methods: {
    window_resizeHandler() {
      this.bannerHeight = document.getElementById('headerBanner') ? document.getElementById('headerBanner').clientHeight : 0;
      this.headerHeight = document.getElementById('headerNavbar') ? document.getElementById('headerNavbar').clientHeight : 0;
    }
  }
  
};
</script>
