export default function(context) {
  const { path } = context.route;

  if (path.endsWith("index")) {
    context.redirect(path.replace("index", ""));
  }
  if (path.endsWith("index.html")) {
    context.redirect(path.replace("index.html", ""));
  } else if (path.endsWith(".html")) {
    context.redirect(path.replace(".html", ""));
  }

  if (path.startsWith("/pages/")) {
    context.redirect(path.replace("/pages/", "/"));
  }

  // hardcoded fix for a url of the pdf that is accessed from outside of our website
  if (
    path.startsWith("/static/assets/evidence/JoCF-2020-NuvoAir-Brompton.pdf")
  ) {
    context.redirect(
      "https://nuvoairwebsite.blob.core.windows.net/websitev3/websitev3/assets/Jo_CF_2020_Nuvo_Air_Brompton_69e9ed417d.pdf"
    );
  }
}
