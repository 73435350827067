import Vue from 'vue';
import gtag, { setOptions, bootstrap } from 'vue-gtag';

Vue.use(gtag, { config: { bootstrap: false }});

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

let gtagBootstrapped = false;

export default (config, inject) => {
  const bootstrapGtag = () => {
    if (gtagBootstrapped) return false;
    gtagBootstrapped = true;

    const { googleAnalyticsId } = config.$config;

    if (googleAnalyticsId) {
      console.log('Setting up Google Analytics with ID', googleAnalyticsId);

      setOptions({config: {id: googleAnalyticsId}});

      return bootstrap().then((gtag) => {
        console.log('Finished bootstrapping Gtag');
      });
    } else {
      console.warn('GOOGLE_ANALYTICS_ID not set up in the environment.');
    }
  }

  inject('bootstrapGtag', bootstrapGtag);
}
