export const state = () => ({
  searchText: '',
  tagsSelected: []
});

export const mutations = {
  setSearchText(state, searchText) {
    state.searchText = searchText;
  },
  setTagsSelected(state, tagsSelected) {
    state.tagsSelected = tagsSelected;
    state.searchText = '';
  }
};

