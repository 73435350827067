<template>
  <div class="layout-header">
    <div :class="overlayClass" @click="overlay_clickHandler" ref="overlay" />
    <div id="headerBanner" class="header-banner" v-if="bannerText && bannerUrl">
      <nuxt-link :to="bannerUrl">{{bannerText}}</nuxt-link>
    </div>
    <nav id="headerNavbar" class="navbar navbar-expand-lg navbar-light fixed-top bg-white border-bottom" :class="{'has-banner': bannerText && bannerUrl}" :style="{top: `${bannerHeight}px`}">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img :src="headerLogoImageUrl" class="navbar-brand-img" alt="NuvoAir Logo" />
        </a>

        <!-- Open Mobile -->
        <button
          v-if="!hideNavigation"
          class="navbar-toggler"
          type="button"
          @click="mobileExpand_clickHandler"
          @mouseup="eventBlocker"
          aria-label="Navbar expand button"
          title="Navbar expand button"
        >
          <span class="navbar-toggler-icon" />
        </button>

        <!-- Mobile and Desktop Nav -->
        <div v-if="!hideNavigation" :class="navbarMobileClass" id="navbarCollapse">
          <!-- Close Mobile -->
          <button
            class="navbar-toggler"
            @click="mobileCollapse_clickHandler"
            @mouseup="eventBlocker"
            type="button"
            aria-label="Navbar collapse button"
            title="Navbar collapse button"
          >
            <i class="fe fe-x" />
          </button>
          <span class="nuvoair-icon"></span>
          <ul class="navbar-nav ms-auto">
            <b-nav-item-dropdown
              ref="bNavItemDropdownMain"
              toggle-class="toggle-main-button"
              class="header-nav-dropdown-custom d-none d-lg-block"
              text="Who We Serve ▾"
              left
            >
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/for-patients" class="nav-link custom-desktop-padding">Patients</nuxt-link>
              </li>
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/for-providers" class="nav-link custom-desktop-padding"
                >Providers</nuxt-link>
              </li>
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/for-health-plans" class="nav-link"
                >Health Plans</nuxt-link>
              </li>
              <li class="nav-item">
                <nuxt-link to="/clinical-trials" class="nav-link"
                >BioPharma</nuxt-link>
              </li>
            </b-nav-item-dropdown>
            <li class="nav-item-company-mobile nav-item d-lg-none">
              <div
                class="btn-collapse-container"
                @click="navbarMainSubmenuCollapse_clickHandler"
                @mouseup="eventBlocker"
              >
                <button class="company-collapse-btn mobile-view-btn">Who we serve</button>
                <span :class="[mainExpanded ? 'drop-up' : 'drop-down']"></span>
              </div>
              <ul :class="mainMobileDropdownItemsClass">
                <li class="nav-item header-border-top">
                  <a href="/for-patients" class="nav-link custom-desktop-padding">Patients</a>
                </li>
                <li class="nav-item header-border-top">
                  <nuxt-link to="/for-providers" class="nav-link custom-desktop-padding"
                  >Providers</nuxt-link>
                </li>
                <li class="nav-item">
                  <nuxt-link to="/for-health-plans" class="nav-link"
                  >Health Plans</nuxt-link>
                </li>
                <li class="nav-item">
                  <nuxt-link to="/clinical-trials" class="nav-link"
                  >BioPharma</nuxt-link>
                </li>
              </ul>
            </li>
            <b-nav-item-dropdown
              ref="bNavItemDropdownEvidence"
              toggle-class="toggle-evidence-button"
              class="header-nav-dropdown-custom d-none d-lg-block"
              text="Evidence ▾"
              left
            >
              <li class="nav-item active desktop-bottom-border">
                <nuxt-link to="/outcomes" class="nav-link">Outcomes</nuxt-link>
              </li>
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/evidence" class="nav-link">Publications</nuxt-link>
              </li>
              <li class="nav-item">
                <nuxt-link to="/whitepapers" class="nav-link mobile-view-sub-link"
                >White Papers</nuxt-link
                >
              </li>
            </b-nav-item-dropdown>
            <li class="nav-item-company-mobile nav-item d-lg-none">
              <div
                class="btn-collapse-container"
                @click="navbarEvidenceSubmenuCollapse_clickHandler"
                @mouseup="eventBlocker"
              >
                <button class="company-collapse-btn mobile-view-btn">Evidence</button>
                <span :class="[evidenceExpanded ? 'drop-up' : 'drop-down']"></span>
              </div>
              <ul :class="evidenceMobileDropdownItemsClass">
                <li class="nav-item">
                  <nuxt-link to="/outcomes" class="nav-link mobile-view-sub-link"
                  >Outcomes</nuxt-link
                  >
                </li>
                <li class="nav-item">
                  <nuxt-link to="/evidence" class="nav-link mobile-view-sub-link"
                  >Publications</nuxt-link
                  >
                </li>
                <li class="nav-item">
                  <nuxt-link to="/whitepapers" class="nav-link mobile-view-sub-link"
                  >White Papers</nuxt-link
                  >
                </li>
              </ul>
            </li>
            <b-nav-item-dropdown
              ref="bNavItemDropdown"
              toggle-class="toggle-company-button"
              class="header-nav-dropdown-custom d-none d-lg-block"
              text="Company ▾"
              left
            >
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/about" class="nav-link">About Us</nuxt-link>
              </li>
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/blog" class="nav-link">Blog</nuxt-link>
              </li>
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/company/news" class="nav-link">News</nuxt-link>
              </li>
              <li class="nav-item desktop-bottom-border">
                <nuxt-link to="/careers" class="nav-link">Careers</nuxt-link>
              </li>
            </b-nav-item-dropdown>
            <li class="nav-item-company-mobile nav-item d-lg-none">
              <div
                class="btn-collapse-container"
                @click="navbarCompanySubmenuCollapse_clickHandler"
                @mouseup="eventBlocker"
              >
                <button class="company-collapse-btn mobile-view-btn">Company</button>
                <span :class="[companyExpanded ? 'drop-up' : 'drop-down']"></span>
              </div>
              <ul :class="companyMobileDropdownItemsClass">
                <li class="nav-item">
                  <nuxt-link to="/about" class="nav-link mobile-view-sub-link"
                    >About Us</nuxt-link
                  >
                </li>
                <li class="nav-item">
                  <nuxt-link to="/blog" class="nav-link mobile-view-sub-link"
                    >Blog</nuxt-link
                  >
                </li>
                <li class="nav-item">
                  <nuxt-link to="/company/news" class="nav-link mobile-view-sub-link"
                    >News</nuxt-link
                  >
                </li>
                <li class="nav-item">
                  <nuxt-link to="/careers" class="nav-link mobile-view-sub-link"
                    >Careers</nuxt-link
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <nuxt-link to="/care-in-the-uk" class="nav-link"
              >Care in the UK</nuxt-link
              >
            </li>
            <li class="nav-item">
              <nuxt-link to="/contact-us" class="nav-link">
                Contact Us
              </nuxt-link>
            </li>
          </ul>


        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import GlobalMixin from "../mixins/GlobalMixin";
import "./Header.scss";

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      mobileExpanded: false,
      mainExpanded: false,
      companyExpanded: false,
      evidenceExpanded: false,
      isMobile: false,
      bannerHeight: 0,
    };
  },
  props: {
    hideNavigation: Boolean
  },
  computed: {
    navbarMobileClass() {
      return "collapse navbar-collapse " + (this.mobileExpanded ? "show" : "");
    },
    navbarMobileSubMenuClass() {
      return "navbar-submenu";
    },
    overlayClass() {
      return "overlay " + (this.mobileExpanded ? "show" : "");
    },
    headerLogoImageUrl() {
      return this.globalSetting("header_logo_image", {}).url;
    },
    bannerText() {
      return this.globalSetting("banner", {});
    },
    bannerUrl() {
      return this.globalSetting("banner_url", {});
    },
    mainMobileDropdownItemsClass() {
      return "company-collapse-dropdown" + (this.mainExpanded ? " show" : "");
    },
    companyMobileDropdownItemsClass() {
      return "company-collapse-dropdown" + (this.companyExpanded ? " show" : "");
    },
    evidenceMobileDropdownItemsClass() {
      return "company-collapse-dropdown" + (this.evidenceExpanded ? " show" : "");
    },
  },
  mounted() {
    this.bannerHeight = document.getElementById('headerBanner') ? document.getElementById('headerBanner').clientHeight : 0;
    window.addEventListener("resize", this.window_resizeHandler);
    this.window_resizeHandler();

    window.addEventListener("mouseup", this.window_mouseupHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.window_resizeHandler);
    window.removeEventListener("mouseup", this.window_mouseupHandler);
  },
  methods: {
    providerPortal_onClickHandler() {
      this.$store.commit('modals/setShowPortalRegionModal', true);
    },
    mobileExpand_clickHandler() {
      this.mobileExpanded = true;
    },
    mobileCollapse_clickHandler() {
      this.mobileExpanded = false;
    },
    navbarCollapse_mouseUpHandler() {
      this.mobileExpanded = false;
    },
    overlay_clickHandler(event) {
      if (event.target === this.$refs.overlay) {
        this.mobileExpanded = false;
      }
    },
    navbarMainSubmenuCollapse_clickHandler() {
      this.mainExpanded = !this.mainExpanded;
    },
    navbarCompanySubmenuCollapse_clickHandler() {
      this.companyExpanded = !this.companyExpanded;
    },
    navbarEvidenceSubmenuCollapse_clickHandler() {
      this.evidenceExpanded = !this.evidenceExpanded;
    },
    window_resizeHandler(event) {
      this.bannerHeight = document.getElementById('headerBanner') ? document.getElementById('headerBanner').clientHeight : 0;

      // Bootstraps mobile breakpoint
      this.isMobile = window.innerWidth < 992;
    },
    eventBlocker(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    isEventWithinCompanyButtonInBootstrapNavDropdown(event) {
      try {
        return (
          event.target.classList.contains("toggle-company-button") ||
          (event.target.innerText === "Company ▾" &&
            event.target.parentNode.classList.contains("toggle-company-button"))
        );
      } catch (error) {
        return false;
      }
    },
    isEventWithinEvidenceButtonInBootstrapNavDropdown(event) {
      try {
        return (
          event.target.classList.contains("toggle-evidence-button") ||
          (event.target.innerText === "Evidence ▾" &&
            event.target.parentNode.classList.contains("toggle-evidence-button"))
        );
      } catch (error) {
        return false;
      }
    },
    isEventWithinMainButtonInBootstrapNavDropdown(event) {
      try {
        return (
          event.target.classList.contains("toggle-main-button") ||
          (event.target.innerText === "Who We Serve ▾" &&
            event.target.parentNode.classList.contains("toggle-main-button"))
        );
      } catch (error) {
        return false;
      }
    },
    window_mouseupHandler(event) {
      if (this.isEventWithinCompanyButtonInBootstrapNavDropdown(event)) {
        return;
      }

      if (this.isEventWithinEvidenceButtonInBootstrapNavDropdown(event)) {
        return;
      }

      if (this.isEventWithinMainButtonInBootstrapNavDropdown(event)) {
        return;
      }

      setTimeout(() => {
        if (this.$refs.bNavItemDropdown) {
          this.$refs.bNavItemDropdown.hide();
        }
        if (this.$refs.bNavItemDropdownEvidence) {
          this.$refs.bNavItemDropdownEvidence.hide();
        }
        if (this.$refs.bNavItemDropdownMain) {
          this.$refs.bNavItemDropdownMain.hide();
        }
        if (!this.mobileExpanded) {
          this.mainExpanded = false;
          this.companyExpanded = false;
          this.evidenceExpanded = false;
        }
        this.mobileExpanded = false;
      }, 20);
    },

  },
};
</script>
