<template>
  <form id="doctorSignupForm" class="get-started-form" @submit.prevent="doctorSignupForm_submitHandler">
    <div class="row">
      <fieldset name="Personal details">
        <div class="col-6 form-field">
          <label for="doctorFirstName">First Name *</label>
          <input id="doctorFirstName" required type="text" name="firstName" value="" @input="validate()" />
        </div>
        <div class="col-6 form-field">
          <label for="doctorLastName">Last Name *</label>
          <input id="doctorLastName" required type="text" name="lastName" value="" @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="doctorEmail">Email Address *</label>
          <input id="doctorEmail" required type="email" name="email" value="" @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="doctorNameOfHospital">Name of Hospital / Clinic</label>
          <input id="doctorNameOfHospital" type="text" name="nameOfHospital" value="" @input="validate()" />
        </div>
        <div class="col-12 form-field">
          <label for="doctorCountry">Country</label>
          <input id="doctorCountry" type="text" name="country" value="" @input="validate()" />
        </div>
      </fieldset>
      <fieldset name="Hospital and plan details">
        <div class="col-12 form-field">
          <p> What service(s) are you interested in? </p>
          <div class="form-check form-check-inline">
            <input
              id="doctorAsthmaAssessment"
              class="form-check-input"
              type="checkbox"
              value="Asthma Assessment Service"
              @change="validate()"
            />
            <label class="form-check-label" for="doctorAsthmaAssessment">Asthma Assessment Service</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="doctorHomeMonitoring"
              class="form-check-input"
              type="checkbox"
              value="Home monitoring for people with asthma, COPD, CF or IPF"
              @change="validate()"
            />
            <label class="form-check-label" for="doctorHomeMonitoring">Home monitoring for people with asthma, COPD, CF or IPF</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="doctorHomeDiagnostics" class="form-check-input" type="checkbox" value="Home diagnostics" @change="validate()" />
            <label class="form-check-label" for="doctorHomeDiagnostics">Home diagnostics</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="doctorVirtualWard" class="form-check-input" type="checkbox" value="Virtual ward" @change="validate()" />
            <label class="form-check-label" for="doctorVirtualWard">Virtual ward</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="doctorOther" class="form-check-input" type="checkbox" value="Other" @change="validate()" />
            <label class="form-check-label" for="doctorOther">Other</label>
          </div>
        </div>
      </fieldset>
      <fieldset name="Message">
        <div class="col-6 form-field">
          <label for="doctorMessage">How can we help you? (optional)</label>
          <textarea id="doctorMessage" />
        </div>
      </fieldset>
      <fieldset name="Consent">
        <div class="col-12 form-field">
          <p
            >I consent to share my data with NuvoAir AB to be contacted to learn more about NuvoAir's services and
            solutions. *</p
          >
          <div class="form-check form-check-inline">
            <input id="doctorGDPRYes" required class="form-check-input" type="checkbox" value="Yes" @input="validate()" />
            <label class="form-check-label" for="doctorGDPRYes">Yes</label>
          </div>
        </div>
      </fieldset>
    </div>
    <button
      type="submit"
      :disabled="submitting || !valid"
      class="btn btn-primary btn-pill main-button form-submit-button"
    >
      Get Started
    </button>
  </form>
</template>
<script>
export default {
  data() {
    return {
      submitting: false,
      valid: false,
    };
  },
  methods: {
    validate() {
      this.valid =
        document.getElementById('doctorFirstName').value &&
        document.getElementById('doctorLastName').value &&
        document.getElementById('doctorEmail').value &&
        document.getElementById('doctorGDPRYes').checked;
    },
    doctorSignupForm_submitHandler() {
      this.submitting = true;

      // Build a condition text list like "Severe Asthma, COPD, Other"
      function buildServicesText() {
        const services = {
          'Asthma Assessment Service': document.getElementById('doctorAsthmaAssessment').checked,
          'Home monitoring for people with asthma, COPD, CF or IPF': document.getElementById('doctorHomeMonitoring').checked,
          'Home diagnostics': document.getElementById('doctorHomeDiagnostics').checked,
          'Virtual ward': document.getElementById('doctorVirtualWard').checked,
          Other: document.getElementById('doctorOther').checked,
        };
        return Object.keys(services)
          .filter((key) => services[key])
          .join('; ');
      }

      // See: backend/api/signups/controllers/signups.js for impl
      const body = {
        first_name: document.getElementById('doctorFirstName').value,
        last_name: document.getElementById('doctorLastName').value,
        email: document.getElementById('doctorEmail').value,
        country: document.getElementById('doctorCountry').value,
        services: buildServicesText(),
        hospital_name: document.getElementById('doctorNameOfHospital').value,
        gdpr_consent: document.getElementById('doctorGDPRYes').checked,
        message: document.getElementById('doctorMessage').value,
      };

      fetch(this.$config.strapiBaseUrl + '/signup/doctor', {
        method: 'post',
        body: JSON.stringify(body),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        this.submitting = false;
        this.$store.commit('modals/setShowDoctorSignupModal', false);
      });
    },
  },
};
</script>
