<template>
  <div class="modals">
    <div
      id="patientSignupModal"
      ref="patientSignupModal"
      class="modal fade get-started-modal patient-signup-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="patientSignupModal"
      aria-hidden="true"
    >
      <patient-signup-modal-dialog />
    </div>

    <div
      id="doctorSignupModal"
      ref="doctorSignupModal"
      class="modal fade get-started-modal doctor-signup-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="doctorSignupModal"
      aria-hidden="true"
    >
      <doctor-signup-modal-dialog />
    </div>

    <div
      id="portalRegionModal"
      ref="portalRegionModal"
      class="modal fade get-started-modal doctor-signup-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="portalRegionModal"
      aria-hidden="true"
    >
      <portal-region-modal-dialog />
    </div>
  </div>
</template>

<script>
import DoctorSignupModalDialog from '~/components/modals/doctorSignupModalDialog';
import PatientSignupModalDialog from '~/components/modals/patientSignupModalDialog';
import PortalRegionModalDialog from '~/components/modals/portalRegionModalDialog';

export default {
  components: {
    PortalRegionModalDialog,
    DoctorSignupModalDialog,
    PatientSignupModalDialog,
  },
  data() {
    return {
      doctorSignupModal: undefined,
      patientSignupModal: undefined,
      portalRegionModal: undefined
    };
  },
  watch: {
    '$store.state.modals.showDoctorSignupModal': function (val, prevVal) {
      if (val) this.doctorSignupModal.show();
      else this.doctorSignupModal.hide();
    },
    '$store.state.modals.showPatientSignupModal': function (val, prevVal) {
      if (val) this.patientSignupModal.show();
      else this.patientSignupModal.hide();
    },
    '$store.state.modals.showPortalRegionModal': function (val, prevVal) {
      if (val) this.portalRegionModal.show();
      else this.portalRegionModal.hide();
    },
  },
  mounted() {
    setTimeout(() => {
      this.doctorSignupModal = new window.bootstrap.Modal(this.$refs.doctorSignupModal);
      this.$refs.doctorSignupModal.addEventListener('hide.bs.modal', () => {
        this.$store.commit('modals/setShowDoctorSignupModal', false);
      });
      this.patientSignupModal = new window.bootstrap.Modal(this.$refs.patientSignupModal);
      this.$refs.patientSignupModal.addEventListener('hide.bs.modal', () => {
        this.$store.commit('modals/setShowPatientSignupModal', false);
      });
      this.portalRegionModal = new window.bootstrap.Modal(this.$refs.portalRegionModal);
      this.$refs.portalRegionModal.addEventListener('hide.bs.modal', () => {
        this.$store.commit('modals/setShowPortalRegionModal', false);
      });
    }, 0);
  },
};
</script>
<style lang="scss">
.modal-overlay {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &.show {
    z-index: 1031;
    position: fixed;
    display: block;
    pointer-events: none;
    background: rgba(50, 50, 50, 0.75);
  }
}

.get-started-modal {
  textarea {
    width: 100%;
  }

  .form-field {
    width: 100%;
  }

  label {
    margin-bottom: 8px;
  }
  &.show {
    display: block;
  }

  .modal-dialog {
    max-width: 740px;
    position: relative;

    .modal-content {
      border-radius: 16px;
      overflow: hidden;
      outline: none;
      border: none;
    }

    .modal-header {
      padding: 32px 64px;
      display: block;
      background-color: #fff;
      border: none;

      .modal-close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        opacity: 1;

        i {
          color: #0073e6;
          font-size: 24px;
        }
      }

      .modal-title {
        font-size: 32px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 32px;
      }
    }

    .modal-body {
      padding: 32px 64px;
    }

    .get-started-form {
      label {
        display: block;
      }

      input, textarea {
        display: block;
        width: 100%;
        padding: 4px 8px;
        border-radius: 3px;
        border: 1px solid #e6e6e6;
      }

      .form-check-input {
        display: inline-block;
        width: auto;
        margin-bottom: 16px;
      }

      .form-check-label {
        display: inline-block;
        margin-bottom: 16px;
      }

      .form-field {
        margin-bottom: 12px;
      }
    }

    .form-submit-button {
      display: table;
      margin: auto;
    }
  }

  .form-image {
    max-width: 100%;
  }

  .form-image-desc {
    font-size: 14px;
    font-weight: 600;
    color: #3a3c3f;
    text-align: center;
    display: block;
    margin-top: 16px;
  }

  .form-disclaimer {
    font-size: 11px;
    color: #92969b;
    font-weight: 400;
    display: block;
    margin-top: 16px;
    line-height: 1.2;
  }

  .modal-body {
    background-color: #fafafa;
  }
}

@media (max-width: 767px) {
  .modal-launch-buttons {
    margin: 32px auto !important;
    display: table;
  }
}

@media (max-width: 560px) {
  .get-started-modal .modal-dialog .modal-header .modal-title {
    font-size: 24px;
  }

  .get-started-modal {
    .modal-dialog {
      .modal-header {
        padding: 50px;

        .form-image-desc {
          margin-bottom: 24px;
        }
      }

      .modal-body {
        padding: 50px;
      }
    }
  }

  .modal-launch-buttons {
    a {
      padding-left: 20px!important;
      padding-right: 20px!important;
      font-size: 14px!important;
    }
  }
}


@media (max-width: 320px){
  .modal-launch-buttons a {
    padding-left: 15px!important;
    padding-right: 15px!important;
    font-size: 13px!important;
  }
}

@media screen and (max-width:280px){
  .modal-launch-buttons a {
    padding-left: 12px !important;
    padding-right: 12px !important;
    font-size: 11px !important;
  }
}
</style>
