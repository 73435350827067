<template>
  <footer class="footer py-8 py-md-11 bg-dark">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 mobile-view responsive-logo-row">
          <div class="padding-left-custom-small">
            <!-- Brand -->
            <img
              src="../assets/img/nuvoair-logo.png"
              alt="..."
              class="footer-brand img-fluid mb-2"
            />

            <!-- Text -->
            <p class="mb-2 light-gray-color mobile-view-title custom-text-color font-size-small">NuvoAir AB © 2023<br>NuovAir Inc. © 2023</p>
            <p class="mb-2 light-gray-color mobile-view-title custom-text-color font-size-small">All rights reserved</p>
          </div>
          <!-- Social -->
          <ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
            <li class="list-inline-item list-social-item me-3">
              <a :href="linkedInUrl" target="_blank">
                <img
                  src="../assets/img/icons/social/linkedin.png"
                  class="list-social-icon"
                  alt="LinkedIn"
                />
              </a>
            </li>
            <li class="list-inline-item list-social-item me-3">
              <a :href="twitterUrl" target="_blank">
                <img
                  src="../assets/img/icons/social/Twitter.png"
                  class="list-social-icon"
                  alt="Twitter"
                />
              </a>
            </li>
            <li class="list-inline-item list-social-item me-3">
              <a
                :href="youtubeUrl"
                class="text-decoration-none"
                target="_blank"
              >
                <img
                  src="../assets/img/icons/social/youtube.png"
                  class="list-social-icon"
                  alt="Youtube"
                />
              </a>
            </li>
            <li class="list-inline-item list-social-item me-3">
              <a
                :href="instagramUrl"
                class="text-decoration-none"
                target="_blank"
              >
                <img
                  src="../assets/img/icons/social/Instagram.png"
                  class="list-social-icon"
                  alt="Instagram"
                />
              </a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-lg-2 custom-padding-mobile responsive-footer-titles">
          <!-- Heading -->
          <h6 class="fw-bold text-uppercase light-gray-color custom-text-color custom-text-size font-size-big">
            Products/Services
          </h6>

          <!-- List -->
          <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
            <li class="mb-3">
              <nuxt-link to="/outcomes" class="text-reset custom-text-size font-size-big dark-gray-color">Outcomes</nuxt-link>
            </li>
            <li class="mb-3">
              <nuxt-link to="/for-providers" class="text-reset custom-text-size font-size-big dark-gray-color"
                >For Providers</nuxt-link
              >
            </li>
            <li class="mb-3">
              <nuxt-link to="/for-health-plans" class="text-reset custom-text-size font-size-big dark-gray-color"
                >For Health Plans</nuxt-link
              >
            </li>
            <li class="mb-3">
              <nuxt-link to="/clinical-trials" class="text-reset custom-text-size font-size-big dark-gray-color"
                >Clinical Trials</nuxt-link
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-lg-2 offset-lg-0 custom-padding-mobile responsive-footer-titles">
          <!-- Heading -->
          <h6 class="fw-bold text-uppercase custom-text-color custom-text-size font-size-big light-gray-color">Company</h6>

          <!-- List -->
          <ul class="list-unstyled text-muted mb-0">
            <li class="mb-3">
              <nuxt-link to="/blog" class="text-reset custom-text-size font-size-big dark-gray-color">Blog</nuxt-link>
            </li>
            <li class="mb-3">
              <nuxt-link to="/about" class="text-reset custom-text-size font-size-big dark-gray-color">About Us</nuxt-link>
            </li>
            <li class="mb-3">
              <nuxt-link to="/contact-us" class="text-reset custom-text-size font-size-big dark-gray-color"
                >Contact Us</nuxt-link
              >
            </li>
            <li class="mb-3">
              <nuxt-link to="/support" class="text-reset custom-text-size font-size-big dark-gray-color">Support</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-lg-2 responsive-footer-titles">
          <!-- Heading -->
          <h6 class="fw-bold text-uppercase text-gray-500 custom-text-color custom-text-size">Legal</h6>

          <!-- List -->
          <ul class="list-unstyled text-muted mb-0">
            <li class="mb-3">
              <nuxt-link
                to="/legal?lang=en_us&type=privacy_policy"
                class="text-reset custom-text-size font-size-big dark-gray-color"
              >
                Privacy Policy
              </nuxt-link>
            </li>
            <li class="mb-3">
              <nuxt-link
                to="/legal?lang=en_us&type=terms_and_conditions"
                class="text-reset custom-text-size font-size-big dark-gray-color"
              >
                Terms and Conditions
              </nuxt-link>
            </li>
            <li class="mb-3">
              <nuxt-link to="/legal?lang=en_us&type=warranty" class="text-reset custom-text-size font-size-big dark-gray-color">
                Warranty
              </nuxt-link>
            </li>
            <li class="mb-3">
              <nuxt-link
                to="/legal?lang=en_us&type=legal_disclaimer"
                class="text-reset custom-text-size font-size-big dark-gray-color"
              >
                Legal Disclaimer
                </nuxt-link>
              </li>
              <li class="mb-3">
                <nuxt-link
                to="/legal?lang=en_us&type=accessibility_statement"
                class="text-reset custom-text-size font-size-big dark-gray-color"
              >
                Accessibility Statement
                </nuxt-link>
              </li>
            </ul>
          </div>
        <div class="col-6 col-md-4 col-lg-3">
          <!-- Heading -->
          <h6 class="fw-bold text-uppercase text-gray-500 custom-text-color custom-text-size">External Links</h6>
          <br />
          <!-- NOTE: WE DO NOT HAVE SUPPORT FOR PORTAL ON MOBILE SO THIS BUTTON SHOULD BE HIDDEN ON MOBILE -->
          <button
            class="btn btn-sm btn-primary btn-pill lift ms-auto custom-text-size mb-4"
            @click='providerPortal_onClickHandler'
          >
            Provider Portal
          </button>

        </div>
        </div>

        <div class="col-12">
          <p class="disclaimer_text">
            Air Next is a CE Certified Medical Device Class IIa spirometer, meeting ISO 26782 and ISO 23747 standards. NuvoAir is ISO 13485 certified.
            Apple, the Apple logo, iPhone are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.
          </p>
        </div>
    </div>
    <client-only>
      <cookie-law @accept="cookieLaw_acceptHandler()" button-decline>
        <div slot="message">{{ cookieConsentMessage }}</div>
      </cookie-law>
    </client-only>
  </footer>
</template>
<script>
import GlobalMixin from "~/mixins/GlobalMixin";
import "./Footer.scss";

let CookieLaw;
if (process.client) {
  CookieLaw = require("vue-cookie-law").default;
}

export default {
  components: { CookieLaw },
  mixins: [GlobalMixin],
  methods: {
    cookieLaw_acceptHandler() {
      console.log("Turning on Gtag");
      this.$bootstrapGtag().then(() => {
        console.log(
          "Gtag has been bootstrapped in response to user accepting cookies."
        );
      });
    },
    providerPortal_onClickHandler() {
      this.$store.commit('modals/setShowPortalRegionModal', true);
    },
  },
  computed: {
    cookieConsentMessage() {
      return this.globalSetting("cookie_consent_message");
    },
    twitterUrl() {
      return this.globalSetting("twitter_url", "");
    },
    youtubeUrl() {
      return this.globalSetting("youtube_url", "");
    },
    instagramUrl() {
      return this.globalSetting("instagram_url", "");
    },
    linkedInUrl() {
      return this.globalSetting("linkedin_url", "");
    },
  },
};
</script>

