<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close modal-close-button btn btn-sm btn-rounded-circle btn-white"
          aria-label="Close"
          @click="closeButton_clickHandler"
        >
          <span class="fe fe-x" />
        </button>
        <div class="row">
          <div class="col-12">
            <h5 class="modal-title text-left m-0">Learn more about partnering with NuvoAir</h5>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <doctor-signup-form />
      </div>
    </div>
  </div>
</template>
<script>
import doctorSignupForm from '../forms/doctorSignupForm';
export default {
  components: {
    doctorSignupForm,
  },
  methods: {
    closeButton_clickHandler() {
      this.$store.commit('modals/setShowDoctorSignupModal', false);
    },
  },
};
</script>
