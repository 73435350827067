export const state = () => ({
  globalSettings: undefined,
  visitorLocation: {}
});

export const mutations = {
  setGlobalSettings(state, globalSettings) {
    state.globalSettings = globalSettings;
  },
  setVisitorLocation(state, visitorLocation) {
    state.visitorLocation = visitorLocation;
  }
};

