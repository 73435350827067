<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import globalSettingsQuery from '~/apollo/queries/globalSettings.gql';

export default {
  apollo: {
    globalSetting: {
      prefetch: true,
      query: globalSettingsQuery,
      result(result, key) {
        if (result.data) {
          this.$store.commit('global/setGlobalSettings', result.data.globalSetting);
        } else {
          throw Error(
            'You need to setup public find access for the Global Setting collection and make sure it is published.'
          );
        }
      },
    },
  },
  beforeMount() {
    this.getVisitorLocation();
  },
  // mounted() {
  //   this.getVisitorLocation()
  // },
  methods: {
    getVisitorLocation() {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.db-ip.com/v2/free/self");

      xhr.send();

      xhr.onload = () => this.$store.commit('global/setVisitorLocation', JSON.parse(xhr.responseText));


    }
  },
};
</script>
