<template>
  <nuxt />
</template>

<script>
import './none.scss';

export default {
  components: {}
};
</script>
