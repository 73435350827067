<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close modal-close-button btn btn-sm btn-rounded-circle btn-white"
          aria-label="Close"
          @click.prevent="closeModal_clickHandler"
        >
          <span class="fe fe-x"></span>
        </button>
        <div class="row">
          <div class="col-12">
            <h5 id="patientSignup" class="modal-title">Get started on NuvoAir</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 col-6">
            <img class="form-image" src="../../assets/img/joinNuvoAirSection/get-started-form-image-1.png" alt="" />
            <span class="form-image-desc">Fill in the form below</span>
          </div>
          <div class="col-sm-3 col-6">
            <img class="form-image" src="../../assets/img/joinNuvoAirSection/get-started-form-image-2.png" alt="" />
            <span class="form-image-desc">We’ll contact your clinic</span>
          </div>
          <div class="col-sm-3 col-6">
            <img class="form-image" src="../../assets/img/joinNuvoAirSection/get-started-form-image-3.png" alt="" />
            <span class="form-image-desc">A starting kit is sent to the clinic*</span>
          </div>
          <div class="col-sm-3 col-6">
            <img class="form-image" src="../../assets/img/joinNuvoAirSection/get-started-form-image-4.png" alt="" />
            <span class="form-image-desc">We’ll get you up and running</span>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <span class="form-disclaimer"
              >*If your clinic is willing to participate NuvoAir will initiate a free trial</span
            >
          </div>
        </div>
      </div>
      <div class="modal-body">
        <patient-signup-form />
      </div>
    </div>
  </div>
</template>
<script>
import patientSignupForm from '../forms/patientSignupForm';

export default {
  components: {
    patientSignupForm,
  },
  methods: {
    closeModal_clickHandler() {
      this.$store.commit('modals/setShowPatientSignupModal', false);
    },
  },
};
</script>
