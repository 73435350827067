<template>
  <core-data-loader>
    <Header hide-navigation />
    <div class="page-container">
      <nuxt />
    </div>
  </core-data-loader>
</template>

<script>
import Header from "~/layouts/Header";
import CoreDataLoader from "~/components/coreDataLoader";
import './menuless.scss';
import "owl-carousel/owl-carousel/owl.carousel.css";

export default {
  components: {
    Header,
    CoreDataLoader
  }
};
</script>
