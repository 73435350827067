import moment from "moment";
import {imageToSize} from "~/util/imageUtil";

export default {
  data: () => ({
    host: '' // This needs to be provided in asyncData on the page
  }),
  methods: {
    formatDateFromString(graphQLDateString) {
      if (!graphQLDateString) return 'N/A';
      return moment(new Date(Date.parse(graphQLDateString))).format(this.$store.state.global.globalSettings.date_format);
    },
    globalSetting(key, _default) {
      return !this.$store.state.global.globalSettings ? _default : this.$store.state.global.globalSettings[key];
    },
    headHelper({ title, description, image, tags, url = undefined}) {
      /**
       * For information about the required and optional properties for OpenGraph, see:
       *
       * https://ogp.me/
       */
      return {
        title,
        meta: [
          {
            hid: "description",
            name: "description",
            content: description
          },
          {
            hid: 'tags',
            property: 'tags',
            content: tags
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: url || this.host + this.$nuxt.$route.fullPath
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'website'
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: title
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: imageToSize(image, 'medium')
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: description
          }
        ]
      };
    }
  }
}
